import ApiService from ".";

const BASE_URL = () => {
  if (process.env.REACT_APP_ENV === "development")
    return process.env.REACT_APP_BASE_URL_DEVELOPMENT;
  else if (process.env.REACT_APP_ENV === "staging")
    return process.env.REACT_APP_BASE_URL_STAGING;
  else if (process.env.REACT_APP_ENV === "production") {
    return process.env.REACT_APP_BASE_URL_PRODUCTION;
  }
};

const FILE_BASE_URL = () => {
  if (process.env.REACT_APP_ENV === "development")
    return process.env.REACT_APP_FILES_DEVELOPMENT_BASE_URL;
  else if (process.env.REACT_APP_ENV === "staging")
    return process.env.REACT_APP_FILES_STAGING_BASE_URL;
  else if (process.env.REACT_APP_ENV === "production")
    return process.env.REACT_APP_FILES_PRODUCTION_BASE_URL;
};

const FILE_BASE_URL_S3 = () => {
  if (process.env.REACT_APP_ENV === "development")
    return process.env.REACT_APP_FILES_DEVELOPMENT_BASE_URL_S3;
  else if (process.env.REACT_APP_ENV === "staging")
    return process.env.REACT_APP_FILES_STAGING_BASE_URL_S3;
  else if (process.env.REACT_APP_ENV === "production")
    return process.env.REACT_APP_FILES_PRODUCTION_BASE_URL_S3;
};

const client = new ApiService({ baseURL: BASE_URL(), loginType: "user" });
const coach = new ApiService({ baseURL: BASE_URL(), loginType: "trainer" });
const ApiCollections = {
  // *********** Auth Module ********
  trainerSignUp: (params) => client.imgPost("trainerSignup", params),
  signIn: (params) => client.imgPost("trainerLogin", params),
  existingPlanSignIn: (params) => client.imgPost("trainerUserLogin", params),
  changePassword: (params) => client.post("reset-password", params),
  getTrainerProfile: (params) => coach.post("app/user/showData", params),
  trainerDetailsEdit: (params) =>
    coach.imgPost("app/user/trainerUpdate", params),
  registrationPart1: (params) => client.post("register", params),

  // *********** Coach Module ********
  getCoachData: (params) => coach.imgPost("coach/data", params),
  getTrainerID: (params) => coach.imgPost("coach_website_url/trainer", params),
  // *********** Nutrition Module ********
  getUserData: () => client.get("app/nutritionconcept"),
  getNutritionType: (params) => client.post("app/nutritiontype/show", params),
  getAllNutritionTypes: (params) =>
    client.post("app/nutritiontype/nutritionTypeList", params),
  updateNutritionType: (params) =>
    client.post("app/nutritiontype/nutritionTypeUpdate", params),
  updateNutritionCalories: (params) =>
    client.post("app/nutritiontype/caloriesUpdate", params),
  getNutritionAdvantages: (params) =>
    client.post("app/nutritiontype/advantagesData", params),
  getNutritionRecipes: (params) =>
    client.post("app/nutritiontype/receipeData", params),
  getWeekWiseCaloriesData: (params) =>
    client.post("app/nutritiontype/weekWiseData", params),
  getMacroNutrientsDonutData: (params) =>
    client.post("app/nutritiontype/pieChartData", params),

  getAllRefunds: () => client.get("app/coachingNutritionCourse/getAllRefunds"),

  // *********** Health Score Module ********
  getSupplements: () => client.get("app/coachSupplement"),
  addProUserDataMailChimp: (params) =>
    client.post("app/mailchimpdata/addProUserDataMailchimp", params),
  addBasicUserDataMailChimp: (params) =>
    client.post("app/mailchimpdata/addBasicUserDataMailchimp", params),
  deleteMailChimpUserDetails: (params) =>
    client.post("app/mailchimpdata/deleteUserDetails", params),
  updateUserSubscription: (params) => client.post("app/coach/stripe", params),
};

export { FILE_BASE_URL, FILE_BASE_URL_S3 };
export default ApiCollections;
